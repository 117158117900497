@use 'sass:math';
.comp_endereco{
    @media screen and (max-width: $tablet-landscape) {
        padding: $sm;
    }
}
.modal-agendamento-entrega{
    .comp_endereco{

        .cep-label.cep-input{
            @include make-xs-column(12, $gutter: $xs $xs $xs 0);
            @include make-sm-column(12, $gutter: $xs $xs $xs 0);
            @include make-md-column(12, $gutter: $xs $xs $xs 0);
        }
    }

}
.booking-endereco{
    .comp_endereco{
        
        .cep-label,.cep-input{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(6, $gutter: $xs $xs $xs);
        }
        
        .lagradouro-label{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(4, $gutter: $xs $xs $xs);
        }
        .lagradouro-input{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(8, $gutter: $xs $xs $xs);
        }
        
        .numero-label{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(5, $gutter: $xs $xs $xs);
        }
        .numero-input{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(7, $gutter: $xs $xs $xs);
        }
        
        .complemento-label{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(6, $gutter: $xs $xs $xs);
        }
        .complemento-input{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(6, $gutter: $xs $xs $xs);
        }
        
        .bairro-label{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(4, $gutter: $xs $xs $xs);
        }
        .bairro-input{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(8, $gutter: $xs $xs $xs);
        }
        
        .municipio-label{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(4, $gutter: $xs $xs $xs);
        }
        .municipio-input{
            @include make-xs-column(12, $gutter: $xs $xs $xs);
            @include make-sm-column(12, $gutter: $xs $xs $xs);
            @include make-md-column(8, $gutter: $xs $xs $xs);
        }
    }

}