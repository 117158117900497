#grupoEconomico{
  .btn{
    height: 37px;
    margin-top: 3px;
  }
    .select-group {
    position: relative;
    width: 100%;
    max-width: 100%;
    background: #fff;
    padding: 3px $sm 0 $sm;
    display: table;
    vertical-align: middle;
    border: 1px solid lighten($search-label-color, 35%);
    border-radius: 4px;

    .dropdown-inline{
        display: block !important;

    }
    .form-control-loading {
      right: 5px !important;
      top: 0px !important;
      background-color: #ffffff;
      height: 30px;
      z-index: 10;
    }
    &.sm {
      width: 130px;
      max-width: 130px;
    }
    &.lg {
      width: 400px;
      max-width: 400px;
    }
    @media screen and (max-width: $mobile) {
      display: inline-block;
      width: 100%;
      max-width: 100%;
      border-right: 0;
      border-bottom: 1px solid lighten($search-label-color, 35%);
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      display: inline-block;
      width: 100%;
      max-width: 50%;
      border-bottom: 1px solid lighten($search-label-color, 35%);
    }
    .control-label {
      font-family: $font-2;
      font-size: em(11px);
      font-weight: 500;
      color: $search-label-color;
      display: block;
      margin: $xs 0 0;
    }
    .form-control {
      font-family: $font-2;
      font-size: em(11px);
      height: 32px;
      font-weight: bold;
      color: $search-content-color;
      background-color: transparent;
      border: 0;
      outline: none !important;
      box-shadow: inherit;
      padding: 0;
    }
  }
  .bootstrap-select,
  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
  }
  .select-group {
    .btn-default {
      @include text-overflow();
      font-family: $font-2;
      font-size: em(11px);
      font-weight: bold;
      color: $search-content-color;
      background-color: transparent;
      border: 0;
      outline: none !important;
      box-shadow: inherit;
      padding: 0; // @media screen and (max-width: $tablet-landscape) {
      //   font-size: em(11px);
      // }
      &:hover,
      &:focus {
        color: lighten($search-content-color, 15%);
        background-color: transparent;
        border: 0;
        outline: none !important;
        box-shadow: inherit;
      }
    }
    .dropdown-menu>.selected>a {
      font-weight: bold;
      .check-mark {
        font-family: alianca-icons!important;
        font-style: normal!important;
        font-weight: 400!important;
        position: relative;
        top: -1px;
        &:before {
          content: "\4a";
        }
      }
    }
    .dropdown-menu {
        max-width: none;
        width: 106%;
        margin-left: -11px;
      .form-control {
        border: 1px solid lighten($search-label-color, 20%);
      }
    }
    .dropdown-menu>li>a {
      font-family: $font-2;
      font-size: em(11px);
      padding: $xs $sm;
    }
    .filter-option {
      @include text-overflow();
      width: 95% !important;
    }
    .dropdown-toggle .caret {
      right: 0;
    }
  }
}
