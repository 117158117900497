.tp-home-carousel {
  $home-title-color: #fff;
  $home-subtitle-color: #ffdc00;
  $home-border-color: #191c20;

  width: 100%;
  border-bottom: 4px solid $home-border-color;

  .container {
    position: relative;
  }

  .carousel-indicators li {
    margin-right: $xs;
  }

  .carousel-inner {
    @media screen and (max-width: $tablet-landscape) {
      overflow: inherit;
    }

    img {
      width: 100%;
      max-height: 450px;

      @media screen and (max-width: $tablet-landscape) {
        min-height: 100%;
      }
    }

    .item {
      max-height: 460px;
      overflow: hidden;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center;
      &#video{
        position: relative;
        background-color: #000e23;
        //background-color: #fff;
        height: 80vh !important;
        max-height: none !important;
        overflow: visible !important;
        padding:0 ;
        .item{
            max-height: none !important;
            overflow: visible !important;
        }
       .video{
            height: 80vh;
            position: relative;
            width: 70%;
            margin: 0 auto;
            video{
                position:absolute;
                height: 100% !important;
                width: 100% !important;
                top: 0;
                left: 0;
            }
       }
    }
      @media screen and (max-width: $tablet-landscape) {
        height: 200px;
      }
    }
  }

  .carousel-caption {
    left: 2%;
    right: 40%;
    bottom: 150px;
    text-align: left;
    z-index: 2;

    @media (min-width: 767px) and (max-width: 1080px) {
      position: absolute;
      left: 5%;
      right: 10%;
      bottom: 50px;
    }

    @media screen and (max-width: $tablet) {
      position: absolute;
      left: 5%;
      right: 10%;
      bottom: 50px;
      max-width: 400px;
    }

    @media (min-width: $tablet) and (max-width: $tablet-landscape) {
      position: absolute;
      left: 5%;
      right: 10%;
      bottom: 70px;
      max-width: 400px;
    }

    .title {
      text-transform: uppercase;
      font-size: em(32px);
      line-height: 1.3em;
      color: $home-title-color;
      font-weight: 600;

      @media screen and (max-width: $tablet-landscape) {
        font-size: em(14px);
      }
    }

    .link {
      text-transform: uppercase;
      font-size: em(18px);
      font-weight: 600;
      color: $home-subtitle-color;

      @media screen and (max-width: $tablet-landscape) {
        font-size: em(10px);
      }

      &:hover {
        color: darken($home-subtitle-color, 5%);
      }
    }

    i {
      font-size: em(20px);
      position: relative;
      top: 5px;
    }

  }
}
.tp-home-carousel-2 {
  $home-title-color: #fff;
  $home-subtitle-color: #ffdc00;
  $home-border-color: #191c20;

  width: 100%;
  border-bottom: 4px solid $home-border-color;

 

  .carousel-indicators li {
    margin-right: $xs;
  }

  .carousel-inner {
    @media screen and (max-width: $tablet-landscape) {
      overflow: inherit;
    }

    img {
      width: 100%;
      max-height: 450px;

      @media screen and (max-width: $tablet-landscape) {
        min-height: 100%;
      }
    }

    .item {
      max-height: 460px;
      @media screen and (max-width: $tablet-landscape) {
        height: 50vh;
      }
      overflow: hidden;
      position: relative;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center;

      img{
        @media screen and (max-width: $tablet-landscape) {
          display: none;
        }
      }

      .white {
        color: white !important;
      }

      h3.title{
        color:#047cca;
        font-weight: normal;
        font-family: $font-3;
      }
      .description {
        font-size: 26px !important;
      }
      h4.subtitle{
          color:#021859;
          font-weight: normal;
          font-family: $font-3;
      }
     &#banner-1{
       .carousel-description{
        @media screen and (min-width: $tablet-landscape) {
          left: 15.5%;
          top: 50px;
          text-align: left;
        }
       }
     }
     &#banner-2{
       .white {
         color: #021859 !important;
         background: rgba(255, 255, 255, .8);
         padding: 1rem;
         border-radius: 5px;
       }
       .carousel-description{
        @media screen and (min-width: $tablet-landscape) {
          left: 15.5%;
          top: 50px;
          text-align: left;

        }
       }
     }
     &#banner-3{
       .carousel-description{
        @media screen and (min-width: $tablet-landscape) {
          left: 15.5%;
          top: 50px;
          text-align: left;
        }
       }
     }
     &#banner-4{
      .carousel-description{
       @media screen and (min-width: $tablet-landscape) {
         left: 15.5%;
         top: 50px;
         text-align: left;
       }
      }
    }     
    }
  }

  .carousel-description {
   
   position: absolute;
    text-shadow: none;;
    z-index: 2;
    width: 100%;
    @media screen and (max-width: $tablet-landscape) {
      text-align: center;
      font-size: 9px;
      text-shadow: 0 0 5px #fff;
      top: 00px;;

    }
    padding: 30px;;
    @media screen and (min-width: $tablet-landscape) {
      width: 50%;
    }

    .title {
      
      font-size: em(30px);
      
      color: $home-title-color;
      margin: 0;

    }
    .subtitle {
      
      font-size: em(28px);
     
      margin: 0;
    

    }

    .link {
      background: #057dc9;
      border-radius: 10px;
      color: #fff;
      padding: 10px 20px;
      margin-top: 20px;;
      display: inline-block;
      font-weight: 200;
      @media screen and (max-width: $tablet-landscape) {
        margin-top: 5px;;
      }
    }



  }
}

.tp-home-footer {
  $footer-text-color: #143058;
  $home-subtitle-color: #ffdc00;
  $home-border-color: #191c20;

  width: 100%;
  padding: 40px 0;
  background-color: #ffffff;

  .container {
    position: relative;
  }

  .title {
    text-transform: uppercase;
    text-align: center;
    font-size: em(16px);
    font-weight: bold;
    color: $footer-text-color;
    margin: 0;

    @media screen and (max-width: $tablet-landscape) {
      font-size: em(12px);
    }
  }

  .social {
    margin-top: $md;
    text-align: center;

    figure {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ffffff;
      margin: 0 auto $xs;

      @media screen and (max-width: $tablet-landscape) {
        width: 35px;
        height: 35px;
      }

      .icon-linkedin,
      .icon-youtube-play {
        display: block;
        color: $action-blue;
        font-size: em(28px) !important;
      }

      .icon-linkedin {
        padding: 7px 0 0 2px;
      }

      .icon-youtube-play {
        padding: 9px 0 0 2px;
      }
    }

    > li {
      width: 70px;
      text-align: center;
      margin: 0 auto;
      display: inline-block;
    }

    a {
      color: $footer-text-color;
      text-transform: uppercase;
      font-size: em(10px);

      @media screen and (max-width: $tablet-landscape) {
        font-size: em(8px);
      }
    }
  }
}

.tp-home-cards {
  $home-cards-bg-1: #2358a1;
  $home-cards-bg-2: $color-primary-active;
  $home-cards-bg-3: #2358a1;
  $home-card-color: #ffffff;

  width: 100%;
  background-color: $color-primary;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width: $tablet-landscape) {
    padding-left: 0;
    padding-right: 0;
  }

  .container-fluid {
    @media screen and (max-width: $tablet-landscape) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .container {
    position: relative;
  }

  .col-xs-12,
  .col-sm-4,
  .col-md-4 {
    padding-left: 0;
    padding-right: 0;
  }

  .tp-home-card {
    @include clearfix();
    padding: 60px $lg;
    text-align: center;

    @media screen and (max-width: $tablet-landscape) {
      padding: $md $lg;
    }

    &.tp-home-card-1 {
      background-color: $color-primary;
    }

    &.tp-home-card-2 {
      background-color: $home-cards-bg-2;
    }

    &.tp-home-card-3 {
      background-color: $color-primary;
    }
  }

  .figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    margin: 0 auto 30px;

    @media screen and (max-width: $tablet-landscape) {
      width: 70px;
      height: 70px;
    }

    img {
      width: 95%;
      padding: 20px;
      margin: 0 auto;

      @media screen and (max-width: $tablet-landscape) {
        padding: 15px;
      }
    }
  }

  .title {
    color: #ffffff;
    font-size: em(22px);
    font-weight: 400;
    text-transform: uppercase;
    margin: $md 0 $sm;
    font-family: $font-3;
    @media screen and (max-width: $tablet-landscape) {
      font-size: em(14px);
    }
  }

  .description {
    font-family: $font-3;
    font-size: em(12px);
    color: $home-card-color;
    min-height: 72px;

    @media screen and (max-width: $tablet-landscape) {
      font-size: em(11px);
      padding-bottom: $sm;
      min-height: inherit;
    }
  }

  .btn-action {
    margin: $md 0;
    padding: $sm $lg;
    border-radius: 18px;
    text-transform: uppercase;
    font-family: $font-3;
    font-size: em(12px);
    background: #0f4c81;

    @media screen and (max-width: $tablet-landscape) {
      margin: 0 0 $sm;
      font-size: em(8px);
    }
  }
}