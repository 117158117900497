@charset "UTF-8";

@font-face {
  font-family: "alianca-icons";
  src:url(#{$icons-path}/alianca-icons.eot);
  src:url(#{$icons-path}/alianca-icons.eot?#iefix) format("embedded-opentype"),
    url(#{$icons-path}/alianca-icons.woff) format("woff"),
    url(#{$icons-path}/alianca-icons.ttf) format("truetype"),
    url(#{$icons-path}/alianca-icons.svg#alianca-icons) format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "alianca-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "alianca-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
  font-family: "alianca-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "alianca-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




.icon-page-copy:before {
  content: "\63";
}
.icon-pencil:before {
  content: "\64";
}
.icon-trash:before {
  content: "\65";
}
.icon-linkedin:before {
  content: "\66";
}
.icon-youtube-play:before {
  content: "\67";
}
.icon-user:before {
  content: "\61";
}
.icon-play-circle:before {
  content: "\62";
}
.icon-angle-left:before {
  content: "\68";
}
.icon-angle-right:before {
  content: "\69";
}
.icon-calendar-o:before {
  content: "\6a";
}
.icon-down-open-big:before {
  content: "\6c";
}
.icon-cancel:before {
  content: "\6d";
}
.icon-lock:before {
  content: "\6e";
}
.icon-forbidden:before {
  content: "\6f";
}
.icon-window-delete:before {
  content: "\70";
}
.icon-home:before {
  content: "\71";
}
.icon-ship:before {
  content: "\72";
}
.icon-book-open:before {
  content: "\73";
}
.icon-checklist:before {
  content: "\74";
}
.icon-marker:before {
  content: "\75";
}
.icon-mail:before {
  content: "\76";
}
.icon-logout:before {
  content: "\77";
}
.icon-up-thin:before {
  content: "\78";
}
.icon-arrow-up:before {
  content: "\79";
}
.icon-down-thin:before {
  content: "\7a";
}
.icon-arrow-down:before {
  content: "\41";
}
.icon-clock:before {
  content: "\42";
}
.icon-hourglass-o:before {
  content: "\43";
}
.icon-page-export:before {
  content: "\44";
}
.icon-truck:before {
  content: "\45";
}
.icon-child:before {
  content: "\46";
}
.icon-comments:before {
  content: "\47";
}
.icon-cloud-upload:before {
  content: "\48";
}
.icon-cloud-download:before {
  content: "\49";
}
.icon-check:before {
  content: "\4a";
}
.icon-plus:before {
  content: "\4b";
}
.icon-telephone:before {
  content: "\4c";
}
.icon-hospital-o:before {
  content: "\4d";
}
.icon-briefcase:before {
  content: "\4e";
}
.icon-paper-plane:before {
  content: "\4f";
}
.icon-paper-plane-o:before {
  content: "\50";
}
.icon-check-1:before {
  content: "\51";
}
.icon-check-square:before {
  content: "\52";
}
.icon-chain-broken:before {
  content: "\53";
}
.icon-bell:before {
  content: "\54";
}
.icon-bell-o:before {
  content: "\55";
}
.icon-alert:before {
  content: "\56";
}
.icon-calendar:before {
  content: "\57";
}
.icon-calendar-times-o:before {
  content: "\58";
}
.icon-calendar-plus-o:before {
  content: "\59";
}
.icon-calendar-check-o:before {
  content: "\5a";
}
.icon-calendar-minus-o:before {
  content: "\30";
}
.icon-building-o:before {
  content: "\31";
}
.icon-expand:before {
  content: "\32";
}
.icon-exchange:before {
  content: "\34";
}
.icon-files-o:before {
  content: "\35";
}
.icon-file-o:before {
  content: "\36";
}
.icon-file-pdf-o:before {
  content: "\37";
}
.icon-file-image-o:before {
  content: "\38";
}
.icon-file-text:before {
  content: "\39";
}
.icon-folder-open:before {
  content: "\21";
}
.icon-map:before {
  content: "\22";
}
.icon-globe:before {
  content: "\23";
}
.icon-map-marker:before {
  content: "\24";
}
.icon-pencil-1:before {
  content: "\25";
}
.icon-tree-file:before {
  content: "\26";
}
.icon-search:before {
  content: "\27";
}
.icon-lightbulb-o:before {
  content: "\28";
}
.icon-feet:before {
  content: "\29";
}
.icon-dry:before {
  content: "\2a";
}
.icon-port:before {
  content: "\2b";
}
.icon-reefer:before {
  content: "\2c";
}
.icon-sliders:before {
  content: "\2d";
}
.icon-csv:before {
  content: "\2e";
}
.icon-external-link:before {
  content: "\33";
}
.icon-angle-down:before {
  content: "\2f";
}
.icon-angle-up:before {
  content: "\3a";
}
.icon-up-dir:before {
  content: "\3b";
}
.icon-down-dir:before {
  content: "\3c";
}
.icon-circle-o:before {
  content: "\3d";
}
.icon-check-circle:before {
  content: "\3e";
}
.icon-help-circled:before {
  content: "\6b";
}
.icon-pallet:before {
  content: "\3f";
}
.icon-team:before {
  content: "\40";
}
.icon-truck-1:before {
  content: "\5b";
}
.icon-data-storege:before {
  content: "\5d";
}
.icon-navegando:before {
  content: "\5f";
}
.icon-package-delivery:before {
  content: "\60";
}
.icon-transport:before {
  content: "\7b";
}
.icon-truck-movement:before {
  content: "\7c";
}
.icon-desembarcadas:before {
  content: "\5e";
}
.icon-next:before {
  content: "\7d";
}
.icon-back:before {
  content: "\7e";
}
.icon-download:before {
  content: "\5c";
}
.icon-printer-1:before {
  content: "\e001";
}
.icon-hashtag-symbol:before {
  content: "\e000";
}
.icon-money-bag-with-dollar-symbol:before {
  content: "\e002";
}
.icon-circle:before {
  content: "\e003";
}
.icon-thumbs-o-up:before {
  content: "\e004";
}
.icon-thumbs-o-down:before {
  content: "\e005";
}
.icon-thumbs-up:before {
  content: "\e006";
}
.icon-thumbs-down:before {
  content: "\e007";
}
.icon-dollar:before {
  content: "\e008";
}
.icon-list-bullet:before {
  content: "\e009";
}
.icon-file:before {
  content: "\e00a";
}
.icon-photo:before {
  content: "\e00b";
}
.icon-truck-aberto:before {
  content: "\e00c";
}
.icon-logistics-transport:before {
  content: "\e00d";
}
.icon-truck-2:before {
  content: "\e00e";
}
.icon-logistics-truck:before {
  content: "\e00f";
}
.icon-handle-with-care:before {
  content: "\e010";
}
.icon-siren-light:before {
  content: "\e011";
}
.icon-humidity:before {
  content: "\e012";
}
.icon-truck-3:before {
  content: "\e013";
}
.icon-pallet-1:before {
  content: "\e014";
}
.icon-icon-safety:before {
  content: "\e015";
}
.icon-devolucao-vazio{
  background:url(../#{$img-path}/icon/retirada-vazio.svg );
  background-size: cover;
  background-repeat: no-repeat;
  float: left;
  display:block;
  margin-top: 5px;
  opacity: 1;
  width: 35%;
  text-align: center;
  font-size: 0.8em;
}
.icon-devolucao-vazio-rastreio:before{
  content: url(../#{$img-path}/icon/retirada-vazio.svg );
}
