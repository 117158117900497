// Mixins

@use "sass:math";

$browser-context: $font-size-base; // Default

@function em($pixels, $context: $browser-context) {
  @return #{calc($pixels / $context)}em;
  //@return #{$pixels}em;
  //@return 0; //teste andre
}

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Position center for vertical and horizontal items
@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

// Helpers - margins and paddings
@mixin space($type: margin, $direction: all, $amount:$medium){
	@if $type == padding {
		@if $direction == all{
			padding: $amount;
		} @else if $direction == top {
			padding-top:$amount
		} @else if $direction == left {
			padding-left:$amount
		} @else if $direction == right {
			padding-right:$amount
		} @else {
			padding-bottom:$amount
		}
	} @else {
		@if $direction == all{
			margin: $amount;
		} @else if $direction == top {
			margin-top:$amount
		} @else if $direction == left {
			margin-left:$amount
		} @else if $direction == right {
			margin-right:$amount
		} @else {
			margin-bottom:$amount
		}
	}
}

// Position center for vertical and horizontal items
@mixin centre($axis: "both"){
  position:absolute;
  @if $axis == "y"{
    top:50%;
    transform: translateY(-50%);
  }
  @if $axis == "x"{
    left:50%;
    transform: translateX(-50%);
  }
  @if $axis == "both"{
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
}
