$search-bg-color: #0a3955;
$search-content-color: #0a4793;
$search-label-color: #8b8b8b;
$search-container-bg-color: #fff;
$search-btn-color: #ffdc00;
$search-min-height: 230px;
$search-select-min-height: 60px;
.main-search {
  width: 100%;
  padding: $lg $md;
  min-height: $search-min-height;
  background-color: $search-bg-color;
  background-position: top center;
  background-size: cover;
  &.main-header {
    min-height: 100px;
    padding: 25px 20px 15px;
  }
  @media screen and (max-width: $tablet-landscape) {
    padding: $lg $xs;
  }
  &.main-programacao-navios {
    background-image: url(#{$img-path}/bg-programacao.png);
  }
  &.main-rastreamento-cargas {
    background-image: url(#{$img-path}/bg-rastreamento.jpg);
  }
  &.main-cotacao {
    background-image: url(#{$img-path}/bg-cotacao.png);
  }
  &.main-booking {
    background-image: url(#{$img-path}/bg-booking.png);
    background-position: top left;
  }
  &.main-documentacao {
    background-image: url(#{$img-path}/bg-documentacao.png);
  }
  &.main-ordens-servico {
    background-image: url(#{$img-path}/bg-ordens-servico.png);
  }
  .more-filters {
    a {
      color: #fff;
    }
  }
  .select-container {
    display: table;
    table-layout: fixed;
    margin: 0 auto;
    border-radius: 8px;
    background-color: $search-container-bg-color;
    box-shadow: 0px 2px 7px 0px rgba(43, 66, 92, 0.5);
    position: relative;
    .dropdown-inline {
      width: 100%;
    }
    .dropdown-menu .empty {
      display: none;
    }
  }
  .select-group {
    position: relative;
    width: 200px;
    @media screen and (min-width: $desktop) {
    /*max-width: 200px;*/
    }
    padding: 3px $sm 0 $sm;
    display: table-cell;
    vertical-align: middle;
    border-right: 1px solid lighten($search-label-color, 35%);
    .form-control-loading {
      right: 2% !important;
      top: 35% !important;
      background-color: #ffffff;
      height: 30px;
      z-index: 10;
    }
    &.sm {
      width: 130px;
      max-width: 130px;
    }
    &.lg {
      width: 400px;
      max-width: 400px;
    }
    @media screen and (max-width: $mobile) {
      display: inline-block;
      width: 50%;
      max-width: 100%;
      border-right: 0;
      border-bottom: 1px solid lighten($search-label-color, 35%);
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      display: inline-block;
      width: 50%;
      max-width: 50%;
      border-bottom: 1px solid lighten($search-label-color, 35%);
    }
    .control-label {
      font-family: $font-2;
      font-size: em(11px);
      font-weight: 500;
      color: $search-label-color;
      display: block;
      margin: $xs 0 0;
    }
    .form-control {
      font-family: $font-2;
      font-size: em(11px);
      height: 32px;
      font-weight: bold;
      color: $search-content-color;
      background-color: transparent;
      border: 0;
      outline: none !important;
      box-shadow: inherit;
      padding: 0;
    }
  }
  .bootstrap-select,
  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
  }
  .select-group {
    .btn-default {
      @include text-overflow();
      font-family: $font-2;
      font-size: em(11px);
      font-weight: bold;
      color: $search-content-color;
      background-color: transparent;
      border: 0;
      outline: none !important;
      box-shadow: inherit;
      padding: 0; // @media screen and (max-width: $tablet-landscape) {
      //   font-size: em(11px);
      // }
      &:hover,
      &:focus {
        color: lighten($search-content-color, 15%);
        background-color: transparent;
        border: 0;
        outline: none !important;
        box-shadow: inherit;
      }
    }
    .dropdown-menu>.selected>a {
      font-weight: bold;
      .check-mark {
        font-family: alianca-icons!important;
        font-style: normal!important;
        font-weight: 400!important;
        position: relative;
        top: -1px;
        &:before {
          content: "\4a";
        }
      }
    }
    .dropdown-menu {
      .form-control {
        border: 1px solid lighten($search-label-color, 20%);
      }
    }
    .dropdown-menu>li>a {
      font-family: $font-2;
      font-size: em(11px);
      padding: $xs $sm;
    }
    .filter-option {
      @include text-overflow();
      width: 95% !important;
    }
    .dropdown-toggle .caret {
      right: 0;
    }
  }
  .datetimepicker {
    position: relative;
    height: 32px;
    .icon {
      font-size: em(14px);
      top: 0;
      right: 0;
    }
    &.input-icon:after {
      font-family: "alianca-icons" !important;
      content: "\57";
      position: absolute;
      left: 80%;
      color: $search-label-color;
      top: 5px;
    }
  }
  .search-action {
    display: inline-block;
    float: right;
    @media screen and (max-width: $tablet) {
      margin-top: $sm;
      width: 100%;
      display: block;
      float: inherit;
    }
  }
  .btn-search {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: $search-btn-color;
    color: #fff;
    font-size: em(20px);
    min-width: 60px;
    @media screen and (max-width: $mobile) {
      width: 94%;
      margin: $sm 0 $sm $sm;
      border-radius: 4px;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      width: 95%;
      margin: $sm 0 $sm 15px;
      border-radius: 4px;
    }
    &:hover {
      background-color: darken($search-btn-color, 7%);
      transition: all 0.3s !important;
    }
  }
  .select-group,
  .btn-search {
    min-height: $search-select-min-height;
    @media screen and (max-width: $mobile) {
      min-height: 40px;
    }
    @media (min-width: $mobile) and (max-width: $tablet) {
      min-height: 40px;
    }
  }
  .select-group {
    @media screen and (max-width: $mobile) {
      min-height: 62px;
    }
  }
  &.main-rastreamento-cargas {

  }
}
